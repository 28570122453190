import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8-8\\@70% 1RM`}</p>
    <p>{`Single Leg Bent Leg Bridge 8-8-8-8/leg`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`400M Run`}</p>
    <p>{`20-Pistols (total)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`Cash out: 20 Back Squats (185/125)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are collecting blankets, scarves and gloves at Louisville East
and The Ville for the homeless this winter. If you’d like to donate
please do so by November 12th. There are bins at each location for your
donations. Thanks!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts today at 10:30 at The Ville. For
more info contact Eric at fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      